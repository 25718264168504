import { Field } from "formik";
import React from "react";
import { MdOutlineRefresh } from "react-icons/md";
import { PREVIEW_WRAPPER } from "../../../Store/actions/actionsTypes";
import ResizableTextarea from "../../../ulitity/ResizableTextarea";
import classes from "./Question.module.css";

const Multichoices = (props) => {
  const {
    title,
    options,
    _id,
    isRequired,
    caller,
    surveyee_count,
    result,
    idAddOn,
    setFieldValue,
    formikValues,
    touched,
    setFieldTouched,
    otherOptionIncluded,
    otherOptionValue,
    remarks,
  } = props;
  let addOnId =
    typeof idAddOn == "number" && idAddOn >= 0 ? `[${idAddOn}]` : "";
  let defaultRemarksValue = result
    ? result[_id + "_remarks"]
      ? typeof idAddOn == "number" && idAddOn >= 0
        ? result[_id + "_remarks"][idAddOn]
        : result[_id + "_remarks"]
      : ""
    : "";
  return (
    <div className={classes.Question}>
      {caller === PREVIEW_WRAPPER && (
        <ResizableTextarea
          conditionalStyle={{
            color: isRequired ? "#e91e63" : "black",
          }}
          title={isRequired ? title + "*" : title}
          additionalClass={classes.Label}
        />
      )}
      {caller === PREVIEW_WRAPPER && surveyee_count && surveyee_count > 0 ? (
        ""
      ) : (
        <>
          <div className={classes.GroupChoices}>
            {options.map((option, index) => {
              let valueCheck = result
                ? result[_id]
                  ? typeof idAddOn == "number" && idAddOn >= 0
                    ? result[_id][idAddOn]
                    : result[_id]
                  : ""
                : "";
              const questionValue =
                typeof idAddOn == "number" && idAddOn >= 0
                  ? formikValues[_id]?.[idAddOn]
                  : formikValues[_id];
              const touchedID =
                typeof idAddOn == "number" && idAddOn >= 0
                  ? touched[_id]?.[idAddOn]
                  : touched[_id];
              return (
                <div>
                  <div key={index}>
                    <label className={classes.RadioContainer}>
                      {option.content}
                      <Field
                        component="input"
                        type="radio"
                        name={`${_id}${addOnId}`}
                        value={option._id}
                        checked={
                          touchedID
                            ? questionValue === option._id
                            : valueCheck === option._id
                        }
                        onChange={(e) => {
                          setFieldValue(`${_id}${addOnId}`, e.target.value);
                          setFieldTouched(`${_id}${addOnId}`, true);
                        }}
                      />
                      <span className={classes.RadioCheckmark}></span>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
          {otherOptionIncluded ? (
            <div>
              <Field
                type="text"
                placeholder={otherOptionValue}
                name={`${_id}_remarks${addOnId}`}
                className={classes.Textarea}
                component="textarea"
                value={remarks}
                defaultValue={defaultRemarksValue}
              />
            </div>
          ) : (
            ""
          )}
          <div className={classes.resetIcon}>
            <MdOutlineRefresh
              title="Deselect"
              size={20}
              onClick={() => {
                setFieldTouched(`${_id}${addOnId}`, true);
                setFieldValue(`${_id}${addOnId}`, "");
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Multichoices;
