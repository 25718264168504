import React, { useState, useRef, useEffect } from "react";
import QuestionPreviewWapper from "./QuestionPreviewWrapper";
import classes from "./QuestionList.module.css";
import { COMPARATIVE } from "../../Store/actions/actionsTypes";
import GroupingDialogContainer from "../../Containers/GroupQuestions/GroupingDialogContainer";
import { useDispatch } from "react-redux";
import { CHANGE_SURVEY_STATE } from "../../Store/actions/actionsTypes";
import Tooltip from "@material-ui/core/Tooltip";

const QuestionList = (props) => {
  let {
    question_order,
    questions,
    current_question_id,
    type = "",
    grouped_questions = [],
    ungrouped_questions = [],
    surveyee_count = 0,
    survey,
    groupedRef = {},
    ungroupedRef = {},
    auto_scroll,
  } = props;
  const [group, setGroup] = useState({});
  const changeSurveyState = useDispatch();
  const questPositionRef = useRef({});
  useEffect(() => {
    if (
      questPositionRef &&
      questPositionRef.current &&
      Object.keys(questPositionRef?.current).length &&
      auto_scroll
    ) {
      questPositionRef.current.scrollIntoView();
    }
  }, [current_question_id]);

  let questionNumber = 0;
  let orderedQuestions =
    // type === COMPARATIVE
    ungrouped_questions
      ? ungrouped_questions.map((question) => {
          if (typeof question === "string") return questions[question];
          else return questions[question._id];
        })
      : question_order.map((id) => questions[id]);
  const onActiveQuestion = (question_id) => {
    props.onActive(question_id);
    props.setOpen(true);
  };
  const generateList = (
    orderedQuestions,
    props,
    current_question_id,
    questionNumber = 0
  ) => {
    return (
      <ul
        ref={type === COMPARATIVE ? ungroupedRef : {}}
        className={classes.QuestionList}
      >
        {orderedQuestions?.map((question, index) => {
          return (
            <li
              key={question?._id}
              ref={question?._id == current_question_id ? questPositionRef : {}}
            >
              <div
                style={{
                  backgroundColor: "#34baf2",
                  width: "100%",
                  borderRadius: "1px",
                  padding: "7px",
                }}
              >
                <h6 style={{ color: "white" }}>{`Question. ${
                  questionNumber + index + 1
                }`}</h6>
              </div>

              {question && (
                <QuestionPreviewWapper
                  question={question}
                  showUp={index !== 0}
                  showDown={index !== orderedQuestions.length - 1}
                  isActive={current_question_id === question?._id}
                  onActive={() => onActiveQuestion(question?._id)}
                  onRemove={
                    () =>
                      // props.type === COMPARATIVE
                      props.onRemoveUngroupQuestion(index)
                    // : props.onRemove(question)
                  }
                  onClone={() => {
                    changeSurveyState({
                      type: CHANGE_SURVEY_STATE,
                      payload: false,
                    });
                    // props.type === COMPARATIVE
                    props.onCloneUnGroupQuestion(
                      question,
                      question?._id,
                      index
                    );
                    // : props.onClone(question, question?._id);
                  }}
                  onUp={
                    () =>
                      // props.type === COMPARATIVE
                      props.onUngroupQuestionUp(index, question._id)
                    // : props.onUp(question)
                  }
                  onDown={
                    () =>
                      // props.type === COMPARATIVE
                      props.onUngroupQuestionDown(index, question._id)
                    // : props.onDown(question)
                  }
                  onOpenModal={() => props.onOpenModal()}
                  surveyee_count={surveyee_count}
                />
              )}
            </li>
          );
        })}
      </ul>
    );
  };
  const editGroup = (obj, ind) => {
    setGroup({ obj: obj, ind: ind });
  };
  return (
    <div
      className={
        surveyee_count > 0
          ? classes.CompQuestionListContainer
          : classes.QuestionListContainer
      }
    >
      {Object.keys(group)?.length > 0 && (
        <GroupingDialogContainer
          surveyRaw={survey}
          setOpenGrouping={setGroup}
          openGrouping={Object.keys(group)?.length > 0}
          editGroup={group}
        />
      )}
      {/* {type !== COMPARATIVE &&
        generateList(orderedQuestions, props, current_question_id)} */}
      {grouped_questions && grouped_questions?.length > 0 && (
        <ul
          ref={groupedRef}
          className={`${classes.QuestionList} ${classes.GroupedQuestionList}`}
        >
          {grouped_questions?.map((obj, ind) => {
            return (
              <li className={classes.CompLI} key={ind}>
                <Tooltip title="Click To Edit Group" placement="top-start">
                  <div
                    style={{
                      backgroundColor: "#34baf2",
                      width: "100%",
                      borderRadius: "1px",
                      padding: "7px",
                      cursor: "pointer",
                    }}
                    onClick={() => editGroup(obj, ind)}
                  >
                    <h6 style={{ color: "white" }}>{obj.groupName}</h6>
                  </div>
                </Tooltip>

                <ul
                  className={`${classes.QuestionList} ${classes.GroupedQuestionList}`}
                >
                  {obj.questions?.map((id, index) => {
                    questionNumber++;
                    let question = questions[id];
                    return (
                      <li
                        className={
                          index + 1 == obj.questions.length &&
                          classes.GroupLastQuestion
                        }
                        key={question?._id}
                        ref={
                          question?._id == current_question_id
                            ? questPositionRef
                            : {}
                        }
                      >
                        <div
                          style={{
                            backgroundColor: "#34baf2",
                            width: "100%",
                            borderRadius: "1px",
                            padding: "7px",
                          }}
                        >
                          <h6
                            style={{ color: "white" }}
                          >{`Question. ${questionNumber}`}</h6>
                        </div>
                        {question && (
                          <QuestionPreviewWapper
                            question={question}
                            showUp={index !== 0}
                            showDown={index !== obj.questions.length - 1}
                            isActive={current_question_id === question?._id}
                            onActive={() => onActiveQuestion(question?._id)}
                            onRemove={() =>
                              props.onRemoveGroupQuestion(ind, index)
                            }
                            onClone={() => {
                              changeSurveyState({
                                type: CHANGE_SURVEY_STATE,
                                payload: false,
                              });
                              props.type === COMPARATIVE
                                ? props.onCloneGroupQuestion(
                                    question,
                                    question?._id,
                                    index,
                                    ind
                                  )
                                : props.onClone(question, question?._id);
                            }}
                            onUp={() => props.onGroupQuestionUp(ind, index)}
                            onDown={() => props.onGroupQuestionDown(ind, index)}
                            onOpenModal={() => props.onOpenModal()}
                            surveyee_count={surveyee_count}
                          />
                        )}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      )}
      {generateList(
        orderedQuestions,
        props,
        current_question_id,
        questionNumber
      )}
    </div>
  );
};

export default QuestionList;
