import React, { useRef, useEffect } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import withDraggableColumns from "react-table-hoc-draggable-columns";
import "react-table-hoc-draggable-columns/dist/styles.css";
import classes from "./ComparativeTable.module.css";
import { questionMap } from "../SurveyPreview/QuestionPreviewWrapper";
import { questionMap as surveyQuestsMap } from "../Survey/QuestionWrapper";

import {
  COMPARATIVE,
  SURVEY_PAGE,
  SURVEY_EDITOR_PAGE,
} from "../../Store/actions/actionsTypes";
import "./ComparativeTable.css";
import CancelIcon from "@material-ui/icons/Cancel";

const ReactTableDraggableColumns = withDraggableColumns(ReactTable);
export default function ComparativeTable(props) {
  const {
    surveyee_count = 0,
    surveyeeUpdate,
    removeSurveyee = "",
    surveyee_list = [],
    survey,
    allQuestionsObject = {},
    questions,
    grouped_questions = [],
    ungrouped_questions = [],
    question_order,
    type,
    caller = "",
    groupedRef = {},
    ungroupedRef = {},
    setFieldValue = () => {},
    formikValues = {},
    touched = {},
    setFieldTouched = () => {},
  } = props;
  const compTable = useRef({});

  useEffect(() => {
    if (type === COMPARATIVE) {
      let questHeights = [];
      let tableRows = Array.prototype.slice.call(
        compTable?.current?.containerRef?.current?.children[0]?.children[0]
          ?.children[1]?.children
      );
      let netCurrentRowheight;
      grouped_questions.length > 0 &&
        Object.keys(groupedRef).length > 0 &&
        Array.prototype.slice
          .call(groupedRef.current.children)
          .map((group, i) => {
            let groupHeadHeight = group.children[0].offsetHeight;
            Array.prototype.slice
              .call(group.children[1].children)
              .map((quest, j) => {
                if (j == 0) {
                  netCurrentRowheight =
                    groupHeadHeight + 25 + quest.offsetHeight;
                } else if (
                  j == group.children[1].children.length - 1 &&
                  i !== groupedRef.current.children.length - 1
                ) {
                  netCurrentRowheight = 25 + quest.offsetHeight + 25;
                } else {
                  netCurrentRowheight = 25 + quest.offsetHeight;
                }
                questHeights.push(netCurrentRowheight);
              });
          });
      ungrouped_questions.length > 0 &&
        Object.keys(ungroupedRef).length > 0 &&
        Array.prototype.slice
          .call(ungroupedRef.current.children)
          .map((quest, i) => {
            let extraHeight =
              (caller === SURVEY_PAGE && i == 0 && 25) ||
              (caller === SURVEY_EDITOR_PAGE && 25) ||
              0;
            questHeights.push(extraHeight + quest.offsetHeight);
          });
      let flag = false;
      (grouped_questions.length > 0 || ungrouped_questions.length > 0) &&
        Object.keys(tableRows).length > 0 &&
        tableRows.map((row, i) => {
          if (questHeights[i] >= row.offsetHeight) {
            row.style.minHeight = `${Math.max(
              questHeights[i],
              row.offsetHeight
            )}px`;
            row.style.minHeight = `${questHeights[i]}px`;
          } else if (questHeights[i] < row.offsetHeight) {
            flag = true;
          }
        });

      if (flag) {
        let questNo = 0;
        grouped_questions.length > 0 &&
          Object.keys(groupedRef).length > 0 &&
          Array.prototype.slice
            .call(groupedRef.current.children)
            .map((group, i) => {
              Array.prototype.slice
                .call(group.children[1].children)
                .map((quest, j) => {
                  if (questHeights[questNo] < tableRows[questNo].offsetHeight) {
                    quest.style.minHeight = `${
                      quest.offsetHeight +
                      (tableRows[questNo].offsetHeight - questHeights[questNo])
                    }px`;
                  }
                  questNo += 1;
                });
            });
        ungrouped_questions.length > 0 &&
          Object.keys(ungroupedRef).length > 0 &&
          Array.prototype.slice
            .call(ungroupedRef.current.children)
            .map((quest, i) => {
              if (questHeights[questNo] < tableRows[questNo].offsetHeight) {
                quest.style.minHeight = ` ${
                  quest.offsetHeight +
                  (tableRows[questNo].offsetHeight - questHeights[questNo])
                }px`;
              }
              questNo += 1;
            });
      }
    }
  });
  // useEffect(() => {
  //   window.onscroll = function () {
  //     myFunction();
  //   };
  //   var tableHeader = document.querySelector(".rt-thead");
  //   // var sticky = tableHeader.offsetTop;
  //   var test = tableHeader.getBoundingClientRect();
  //   function myFunction() {
  //     if (window.pageYOffset >= test.top) {
  //       tableHeader.classList.add("stickyheader");
  //     } else {
  //       tableHeader.classList.remove("stickyheader");
  //     }
  //   }
  // }, []);

  // const [surveyee, setSurveyee] = React.useState(surveyee_list);
  const handleDeleteColumn = (key) => {
    console.error(key);
  };
  const handleDragSuccess = (
    draggedColumn,
    targetColumn,
    oldIndex,
    newIndex,
    oldOffset,
    newOffset
  ) => {
    console.error(
      draggedColumn,
      targetColumn,
      oldIndex,
      newIndex,
      oldOffset,
      newOffset
    );
  };
  const generateTableData = () => {
    let questionsOrder = [];

    if (type === COMPARATIVE) {
      grouped_questions &&
        grouped_questions.map((obj) => {
          obj.questions.map((questId) => {
            questionsOrder.push(questId);
          });
        });

      questionsOrder = [...questionsOrder, ...ungrouped_questions];
    } else {
      questionsOrder = [...question_order];
    }

    let dataArr = [];
    for (let i = 0; i < questionsOrder.length; i++) {
      let rowObj = {};

      for (let j = 1; j <= surveyee_count; j++) {
        let questId = questionsOrder[i];
        rowObj[String(j)] =
          caller == SURVEY_PAGE
            ? surveyQuestsMap[allQuestionsObject[questId].type](
                allQuestionsObject[questId],
                COMPARATIVE,
                "",
                j - 1,
                setFieldValue,
                formikValues,
                touched,
                setFieldTouched
              )
            : questionMap[questions[questId].type](
                questions[questId],
                COMPARATIVE
              );
      }
      dataArr.push(rowObj);
    }

    return dataArr;
    // const data = [
    // {
    //   name: <input></input>,
    //   age: <span>d</span>,
    //   friend: {
    //     name: "Jason Maurer",
    //     age: 23,
    //   },
    //   id: 1,
    // },
    // {
    //   name: "2",
    //   age: 26,
    //   friend: {
    //     name: "Jason Maurer",
    //     age: 23,
    //   },
    //   id: 2,
    // },
    // ];
  };
  const addSurveyyeName = (e, i) => {
    let prevList = [...surveyee_list];
    let surveyeeObj = prevList[i - 1];
    prevList[i - 1] = { ...surveyeeObj, name: e.target.value };

    surveyeeUpdate(prevList);
  };
  const addSurveyyeEmail = (e, i) => {
    let prevList = [...surveyee_list];
    let surveyeeObj = prevList[i - 1];
    let emailId = e.target.value.toLowerCase().replace(/\s/g, "");

    prevList[i - 1] = { ...surveyeeObj, email: emailId };
    surveyeeUpdate(prevList);
  };
  const removeColumn = (i) => {
    let prevList = [...surveyee_list];
    prevList.splice(i - 1, 1);

    removeSurveyee([...prevList]);
  };
  const generateColumns = () => {
    let columns = [];

    for (let i = 1; i <= surveyee_count; i++) {
      columns.push(
        {
          Header: () => (
            <div className={classes.ColumnHeader} key={i}>
              {caller !== SURVEY_PAGE && (
                <CancelIcon fontSize="small" onClick={() => removeColumn(i)} />
              )}

              <input
                className={` ${classes.InputField} ${
                  surveyee_list[i - 1]?.name.length === 0 &&
                  classes.InputFieldMandatory
                }`}
                placeholder="Name*"
                // onClick={() => handleDeleteColumn("name")}
                onChange={(e) =>
                  caller !== SURVEY_PAGE && addSurveyyeName(e, i)
                }
                // value={surveyeeOrder[i - 1]?.name}
                value={
                  surveyee_list[i - 1]?.name ? surveyee_list[i - 1]?.name : ""
                }
                disabled={caller === SURVEY_PAGE ? true : false}
              ></input>
              <input
                className={`${classes.InputField} ${
                  surveyee_list[i - 1]?.email.length === 0 &&
                  classes.InputFieldMandatory
                }`}
                placeholder="Email Id*"
                // onClick={() => handleDeleteColumn("name")}
                onChange={(e) =>
                  caller !== SURVEY_PAGE && addSurveyyeEmail(e, i)
                }
                // value={surveyeeOrder[i - 1]?.name}
                value={
                  surveyee_list[i - 1]?.email ? surveyee_list[i - 1].email : ""
                }
                disabled={caller === SURVEY_PAGE ? true : false}
              ></input>
            </div>
          ),
          minWidth: 255,
          // Cell: ({ row }) => {
          //   console.log(row, " cellRows");
          // },
          accessor: String(i),
        }
        // {
        //   Header: () => {
        //     return null;
        //   },
        //   columnId: i,
        //   accessor: String(i),
        //   Cell: ({ row }) => {
        //     const data = row.original;
        //     console.log("row original data is", data);

        //     return <span className="test"></span>;
        //   },
        // }
      );
    }
    return columns;
  };

  const onRowClick = (state, rowInfo, column, instance) => {
    return {
      onClick: (e) => {},
    };
  };
  return (
    <div className={classes.ComparativeTableContainer}>
      <ReactTableDraggableColumns
        minRows={0}
        draggableColumns={{
          mode: "reorder",
          draggable: [],
        }}
        ref={compTable}
        // draggableColumns={{
        //   mode: "reorder",
        //   draggable: Array.from({ length: surveyee_count }, (_, i) =>
        //     String(i + 1)
        //   ),
        // onDraggedColumnChange: (columns) => {
        // },
        // onDropSuccess: (
        //   draggedColumn,
        //   targetColumn,
        //   oldIndex,
        //   newIndex,
        //   oldOffset,
        //   newOffset
        // ) => {
        //   // console.log(surveyee_list, " >rearranged2nd");

        //   surveyeeColumnsRearrange(oldIndex, newIndex);
        // },
        // }}
        showPaginationBottom={false}
        data={generateTableData()}
        columns={generateColumns()}
        getTheadThProps={onRowClick}
        pageSize={1000}
      />
    </div>
  );
}
